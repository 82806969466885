runFunction(function() {
    const KEYBOARD_FOCUS_CLASS = 'isKeyboardFocused';
    const ANCHOR = 'a';
    const BUTTON = 'button';
    const FOCUSED_CLASS = KEYBOARD_FOCUS_CLASS;
    let lastKeyPress = true;
    $(document).onPage('keydown.keyboardFocusGlobal', function() {
        lastKeyPress = true;
    });
    $(document).onPage('mousedown.keyboardFocusGlobal', function() {
        lastKeyPress = false;
    });
    $.keyboardFocus = function(classNameMappings) {
        if (!classNameMappings) {
            throw new Error('Please pass a mapping of selectors to focus class names to `keyboardFocus`.');
        }
        for (var elementClass in classNameMappings) {
            if (classNameMappings.hasOwnProperty(elementClass)) {
                bindKeyboardFocusEvents($(document), elementClass, classNameMappings[elementClass]);
            }
        }
    };
    $.offKeyboardFocus = function() {
        $(document).offPage('.keyboardFocus');
    };
    $.fn.keyboardFocus = function(classNames) {
        if (!classNames) {
            throw new Error('Please pass your focus class names into `$(...).keyboardFocus(...)`.');
        }
        bindKeyboardFocusEvents(this, null, classNames);
        return this;
    };
    $.fn.offKeyboardFocus = function() {
        this.offPage('.keyboardFocus');
    };
    function bindKeyboardFocusEvents($el, elementClass, classNames) {
        const pureClassNames = classNames.replace(/\./g, '');
        let $target;
        $el.onPage('mousedown.keyboardFocus', elementClass, function(e) {
            $(e.target).removeClass(pureClassNames);
        });
        $el.onPage('focus.keyboardFocus', elementClass, function(e) {
            if (lastKeyPress) {
                $target = $(e.target);
                $target
                    .addClass(pureClassNames)
                    .trigger('keyboardFocus');
            }
        });
        $el.onPage('blur.keyboardFocus', elementClass, function(e) {
            $target = $(e.target);
            $target.removeClass(classNames);
            if (lastKeyPress) {
                $target.trigger('keyboardBlur');
            }
        });
    }
    $.keyboardFocus({
        [`${ANCHOR}`]: FOCUSED_CLASS,
        [`${BUTTON}`]: FOCUSED_CLASS
    });
});
